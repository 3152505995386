import React from "react";
import withLayout from "../components/withLayout";
import { graphql } from "gatsby";
import TableOfContents from "../components/TableOfContents";
import {
  GuideContainer,
  WhiteContentBox,
  PageHeader,
  PageContainer
} from "../components/common";
import { PoseGroup } from "react-pose";

// root page for the knowledge base canvis.app/guide
// guide pages do not use Divider / expanding margins.
// PageContainer is used to keep the header max 1000px, while the rest of the guide can go wider.
const Guide = ({ content, TOC, location }) => (
  <>
    <PageContainer>
      <PageHeader>
        <h1>{content.header}</h1>
      </PageHeader>
    </PageContainer>
    <GuideContainer>
      <TableOfContents
        header={content.toc_header}
        overline={content.header}
        TOC={TOC}
        currentPath={location.pathname}
        buttonText={content.toc_button}
      />
      <PoseGroup animateOnMount={true}>
        <WhiteContentBox key="boxkey">
          <section
            className="markdown-content"
            dangerouslySetInnerHTML={{ __html: content.root_content }}
          />
        </WhiteContentBox>
      </PoseGroup>
    </GuideContainer>
  </>
);

export default withLayout(Guide);

export const query = graphql`
  query getGuide($Locale: String!) {
    TOC: allAirtable(
      filter: {
        table: { eq: "Guide Sections" }
        data: { Locale: { eq: $Locale } }
      }
      sort: { fields: data___Position, order: ASC }
    ) {
      ...TOC
    }
    content: allAirtable(
      filter: { table: { eq: "Guide" }, data: { Locale: { eq: $Locale } } }
    ) {
      ...static
    }
    layout: allAirtable(
      filter: { table: { eq: "Layout" }, data: { Locale: { eq: $Locale } } }
    ) {
      ...layout
    }
  }
`;
